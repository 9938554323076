import { axios } from "@/services/axios/config";
import { getUserFromLocalStorage } from '@/utils/functions'

/**
 * API function to fetch list of addresses from gouv.fr that match the search term
 * @param searchTearm 
 * @returns { Promise }
 */
export const getAddresses = async (searchTearm: any) => {
    try {
        const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${searchTearm}&autocomplete=1&type=municipality`);
        return await Promise.resolve(response.data);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to modify an address
 * @param id 
 * @param payload 
 * @param config 
 * @returns { Promise }
 */
export const modifyAddress = async (id: any, payload: any, config: any) => {
    try {
        const response = await axios.put(`quotes/update_address/${id}/`, payload, config);
        return await Promise.resolve(response.data);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to create an address
 * @param payload 
 * @returns { Promise }
 */
export const createAddress = async (payload: any) => {
    const user = getUserFromLocalStorage()

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user?.token.access}`
        }
    }
    try {
        const response = await axios.post('quotes/create_address/', payload, config);
        return await Promise.resolve(response.data);
    } catch (err) {
        return await Promise.reject(err);
    }
}