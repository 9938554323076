import { defineStore, storeToRefs } from 'pinia'
import { useUserStore } from "@/stores/user";
import { nextTick, reactive, ref, watch } from 'vue';
import { useAlertsStore } from '@/stores/alerts'
import validationSchema from '@/validation/companyDetailsSchema'

import i18n from '@/localization/i18n'
import { modifyProfileInfo } from '@/services/modules/AuthService';
import { IUser } from '@/models/interfaces/interfaces';
import { formateCompanyReference, replaceEmptyWithNull } from '@/utils/functions';

const t = i18n.global.t

export const useCompanyDetailStore = defineStore('company-detatils', () => {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const saveLoading = ref(false)
    const hasChanges = ref(false)
    const iAmProfessional = ref(user.value.ctype === "g_client_type_Professional")

    const formData = reactive({
        ctype: user.value.ctype,
        company_name: user.value.company_name ?? "",
        company_reference: formateCompanyReference(user.value.company_reference),
        company_tax_number: user.value.company_tax_number ?? "",
    })

    const formErrors = reactive({
        company_name: '',
        company_reference: '',
        company_tax_number: '',
    })


    // Set values when the user object changes
    watch(user, () => {
        formData.ctype = user.value.ctype
        formData.company_name = user.value.company_name ?? ""
        formData.company_reference = formateCompanyReference(user.value.company_reference)
        formData.company_tax_number = user.value.company_tax_number ?? ""
    })

    watch(iAmProfessional, (newVal) => {
        formData.ctype = newVal === true ? "g_client_type_Professional" : "g_client_type_Individual"
        hasChanges.value = true
    })

    watch(formData, (newVal) => {
        if (
            newVal.company_name !== user.value.company_name ||
            newVal.company_reference !== user.value.company_reference ||
            newVal.company_tax_number !== user.value.company_tax_number
        ) {
            hasChanges.value = true
        }
    })

    const validateRG = async () => {
        let errorCount = 0;
        let isSiretValid = false

        var comp_reference: string = formData.company_reference.replace(/\s/g, '')
        if (comp_reference.length !== 9 && comp_reference.length !== 14) {
            formErrors.company_reference = t("m_msg_invalid_format")
            errorCount++
        } else isSiretValid = true

        if (formData.company_tax_number && /^FR\d{11}$/.test(formData.company_tax_number) === false) {
            formErrors.company_tax_number = t("m_msg_invalid_format")
            errorCount++
        } else {
            if (isSiretValid && formData.company_tax_number) {
                const referencePart = comp_reference.substring(0, 9);
                const taxNumberPart = formData.company_tax_number.substring(formData.company_tax_number.length - 9);
                if (referencePart !== taxNumberPart) {
                    formErrors.company_tax_number = t("m_msg_invalid_format");
                    errorCount++;
                }
            }
        }

        return errorCount === 0
    }

    const saveChanges = async () => {
        resetFormErrors()
        if (hasChanges.value) {
            let payload
            saveLoading.value = true
            if (iAmProfessional.value) {
                try {
                    await validationSchema.validate(formData, { abortEarly: false })
                    let isRGValid = await validateRG()
                    if (isRGValid === false) {
                        saveLoading.value = false
                        return
                    }
                    payload = {
                        ctype: formData.ctype,
                        company_name: formData.company_name,
                        company_reference: formData.company_reference.replace(/\s/g, ''),
                        company_tax_number: formData.company_tax_number,
                    }
                } catch (error: any) {
                    error.inner.forEach((e: any) => {
                        // @ts-ignore
                        formErrors[e.path] = e.message;
                    });
                    saveLoading.value = false
                    return
                }
            } else {
                payload = {
                    ctype: formData.ctype,
                    company_name: formData.company_name,
                    company_reference: formData.company_reference.replace(/\s/g, ''),
                    company_tax_number: formData.company_tax_number,
                }
            }

            payload = {
                ctype: replaceEmptyWithNull(payload.ctype),
                company_name: replaceEmptyWithNull(payload.company_name),
                company_reference: replaceEmptyWithNull(payload.company_reference),
                company_tax_number: replaceEmptyWithNull(payload.company_tax_number),
            };

            try {
                const response = await modifyProfileInfo(payload)
                let userResponse = response.data
                updateUserState(userResponse)

                nextTick(() => {
                    hasChanges.value = false
                })

                saveLoading.value = false

                const alerts = useAlertsStore()
                alerts.putAlert({ 'code': 'updateComapyInfoSuccess', 'scope': 'general' })
            } catch (error: any) {
                saveLoading.value = false

                const alerts = useAlertsStore()
                alerts.putAlert({ 'code': 'updateComapyInfoFail', 'scope': 'general' })

            }
            saveLoading.value = false
        }
    }

    const updateUserState = (data: IUser) => {
        userStore.addUser(data)
        sessionStorage.setItem('user', JSON.stringify(user.value))
    }

    const resetCompanyDetails = () => {
        if (hasChanges.value === false) return

        formData.ctype = user.value.ctype
        formData.company_name = user.value.company_name ?? ""
        formData.company_reference = formateCompanyReference(user.value.company_reference)
        formData.company_tax_number = user.value.company_tax_number ?? ""

        iAmProfessional.value = user.value.ctype === "g_client_type_Professional"
        hasChanges.value = false
    }

    const resetFormErrors = () => {
        formErrors.company_name = ''
        formErrors.company_reference = ''
        formErrors.company_tax_number = ''
    }



    const clearData = () => {
        resetCompanyDetails()
        resetFormErrors()
        const loginAlerts = useAlertsStore()
        loginAlerts.clearAlerts()

        hasChanges.value = false
    }


    return {
        saveLoading,
        hasChanges,
        formData,
        formErrors,
        iAmProfessional,

        saveChanges,
        clearData,
        resetCompanyDetails,
    }
})