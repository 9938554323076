<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import useGlobalStore from "@/stores/ui/global.ts";
import {storeToRefs} from "pinia";
// @ts-ignore
import { useI18n } from 'vue-i18n';
import { usePageContext } from 'vike-vue/usePageContext'
import Link from '@/views/components/Link.vue'

// Local variables
const { t } = useI18n()
const pageContext = usePageContext()

const globalStore = useGlobalStore()
const { showCookieBanner } = storeToRefs(globalStore)

// Refs
// const showBanner = ref(false)
const alreadyConsented = ref(false)

/**
 * This function is used to avoid showing the cookies pop up the payment screen for a pro.
 */
const checkRoute = (route: any) => {
    const isPaymentRoute = route.urlPathname.includes("/payment/") || route.urlPathname.includes("/payment-success");
    if (typeof window !== 'undefined') {
        if (localStorage.getItem("cookie-consent")) {
          alreadyConsented.value = true;
        }

        if (isPaymentRoute) {
          localStorage.setItem("cookie-consent", "yes")
          alreadyConsented.value = true;
        }
    }

    setTimeout(() => {
        globalStore.setShowCookieBanner(!isPaymentRoute)
    }, 3000);
};

//watchers
watch(pageContext, (newRoute) => {
  checkRoute(newRoute);
}, { immediate: true });

// Lifecycle hooks
onMounted(() => {
    checkRoute(pageContext)
    // console.log("showBanner", showCookieBanner.value)
    // console.log("alreadyConsented", alreadyConsented.value)
})

// Functions
const acceptCookies = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem("cookie-consent", "yes")
      globalStore.setShowCookieBanner(false)
    }
};

// const refuseCookies = () => {
//     showBanner.value = false
//     window.close();
//     window.location.href = 'https://www.google.fr/'
// };

const closeBanner = () => {
  globalStore.setShowCookieBanner(false)
};
</script>

<template>
    <div v-if="showCookieBanner && !alreadyConsented" class="banner card shadow-lg p-2 p-md-3 animate__animated animate__fadeIn">
        <button @click="closeBanner" class="close-btn">&times;</button>
        <div class="d-flex align-items-center">
            <div class="me-2">
                <span class="d-none d-sm-none d-md-block d-lg-block d-xl-block" style="color: #FB9307;">
                    <cookie :size="48"></cookie>
                </span>
                <span class="d-block d-sm-block d-md-none d-lg-none d-xl-none" style="color: #FB9307;">
                    <cookie :size="36"></cookie>
                </span>
            </div>
            <div>
                <h3 class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    {{ t('w_txt_cookieBanner_heading') }}
                </h3>
                <h5 class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                    {{ t('w_txt_cookieBanner_heading') }}
                </h5>
            </div>
        </div>
        <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block text-dark ms-2 ms-md-4 me-1 mt-1">
            <p class="my-0">
                {{ t('w_txt_cookieBanner_paragraph') }}
            </p>
            <div class="mt-2" style="margin-left: 30px !important;">
                <p class="mb-0">
                    {{ t('w_txt_functional_cookies') }}
                </p>
                <p>
                    {{ t('w_txt_analytic_cookies') }}
                </p>
            </div>
            <p>
                {{ t("w_txt_cookieBanner_validatingAndContinuingCookiesPloicy") }} <Link href="/cookies-policy"
                    style="text-decoration: underline !important;" class="signUpLink" target="_blank">{{
                        t('w_txt_home_footerCookiesPolicy') }} </Link> <open-in-new style="color: #FB9307 !important;" :size="15"></open-in-new>
            </p>
        </div>
        <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none text-dark ms-2 ms-md-4 me-1 mt-1">
            <div>
                <small>
                    {{ t('w_txt_cookieBanner_paragraph') }}
                </small><br>
                <div class="mt-2" style="margin-left: 15px !important;">
                    <small>
                        {{ t('w_txt_functional_cookies') }}
                    </small><br>
                    <small>
                        {{ t('w_txt_analytic_cookies') }}
                    </small><br>
                </div>
            </div>
            <div class="mt-2">
                <small>
                    {{ t("w_txt_cookieBanner_validatingAndContinuingCookiesPloicy") }} <Link to="/cookies-policy/"
                        style="text-decoration: underline !important;" class="signUpLink" target="_blank">{{
                            t('w_txt_home_footerCookiesPolicy') }} </Link> <open-in-new style="color: #FB9307 !important;" :size="15"></open-in-new>
                </small>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-2">
            <button @click="acceptCookies" class="btn btn-primary">{{ t('w_act_csProfile_confirm') }}
            </button>
        </div>
    </div>
</template>

<style scoped>
.banner {
    width: 30rem;
    min-height: 13rem;
    position: fixed;
    bottom: 4rem;
    left: 2rem;
    background-color: whitesmoke;
    z-index: 99;
}

.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    font-size: 3rem;
    border: none;
    color: grey;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .banner {
        min-height: 9rem;
        /* min-width: 10rem; */
        max-width: 96vw;
        position: fixed;
        bottom: 0.1rem;
        left: 2vw;
        right: 2vw;
        background-color: whitesmoke;
        z-index: 99;
    }
}
</style>