import { defineStore, storeToRefs } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { createAddress, modifyProfileInfo } from "@/services";
import validationSchema from '@/validation/basicProfilInfoSchema'
import { IAddress, IUser } from "@/models/interfaces/interfaces";
import { useAlertsStore } from '@/stores/alerts'
import { useAvatarFormStore } from '../avatarForm/avatarForm'
import { nextTick } from "vue";
import constants from '@/utils/constants'




export const useUserInfoFormStore = defineStore('user-info-form', () => {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    let address = ref(user.value.address)

    const saveLoading = ref(false)
    const hasChanges = ref(false)

    const formData = reactive({
        first_name: user.value.first_name,
        last_name: user.value.last_name,
        username: user.value.username,
        gender: user.value.gender,
        phone: user.value.phone ?? '',
    })

    const formErrors = reactive({
        first_name: '',
        last_name: '',
        username: '',
        gender: '',
        phone: '',
    })

    // This forces hasChanges to be true again
    // watch(user, () => {
    //     resetFormData()
    // })

    watch(formData, (newVal) => {
        if (
            newVal.first_name !== user.value.first_name ||
            newVal.last_name !== user.value.last_name ||
            newVal.username !== user.value.username ||
            newVal.gender !== user.value.gender ||
            (newVal.phone !== user.value.phone)
        ) {
            hasChanges.value = true
        }
    })

    watch(address, (newVal) => {
        if (
            newVal?.complement !== user.value.address?.complement ||
            newVal?.lat !== user.value.address?.lat ||
            newVal?.long !== user.value.address?.long ||
            newVal?.mailbox_compl !== user.value.address?.mailbox_compl ||
            newVal?.zipcode !== user.value.address?.zipcode ||
            newVal?.street !== user.value.address?.street ||
            newVal?.town !== user.value.address?.town
        ) {
            hasChanges.value = true
        }
    })

    const is_address_empty = (address_obj: IAddress) => {
        if (!address_obj) {
            return true
        }

        return Object.values(address_obj).every((value) => {
            return value === null || value === undefined || value === ''
        })
    }

    const restFormErrors = () => {
        formErrors.first_name = ''
        formErrors.last_name = ''
        formErrors.username = ''
        formErrors.gender = ''
        formErrors.phone = ''
    }

    const isIdentityInfoModified = computed(() => {
        if (((formData.first_name !== user.value.first_name)
            || (formData.last_name !== user.value.last_name))) {
            return true
        } else {
            return false
        }
    })

    const openBeforeSaveModal = () => {
        let triggerButton = document.getElementById('beforeSaveButton')
        triggerButton?.click()
    }

    const saveChanges = async () => {
        restFormErrors()

        if (!user.value.updatable && isIdentityInfoModified.value) {
            openBeforeSaveModal()
        } else {
            saveLoading.value = true
            try {
                let validatedFormData = await validationSchema.validate(formData, { abortEarly: false })
                validatedFormData.phone = validatedFormData.phone ? validatedFormData.phone : null
                try {
                    // Save avatar first
                    const avatarFormStore = useAvatarFormStore()
                    // check if avatar is uploaded then upload it
                    if (avatarFormStore.hasChanges) {
                        await saveAvatar()
                    }

                    // check if there are changes on the user
                    // basic info form
                    // only call backend if there are changes
                    if (hasChanges.value) {
                        let payload

                        // check if user had address then add the modified address object
                        if (user.value.address && !user.value.address.is_empty && !is_address_empty(address.value as IAddress)) {
                            payload = {
                                ...validatedFormData,
                                address: address.value
                            }
                        } else {
                            payload = {
                                ...validatedFormData
                            }
                        }

                        const response = await modifyProfileInfo(payload)

                        let userResponse = response.data

                        // create address if user didn't have an address
                        if (user.value.address?.is_empty && !is_address_empty(address.value as IAddress)) {
                            let address_payload = {
                                "resource_type": "client__address",
                                "resource_id": user.value.party_id,
                                "address": address.value
                            }

                            const create_address_response = await createAddress(address_payload)
                            userResponse.address = create_address_response
                        }

                        updateUserState(userResponse)
                    }

                    // Wait for all pending updates to finish
                    // if not hasChanges will be reset back to true
                    // when you update user state with response from backend
                    nextTick(() => {
                        hasChanges.value = false
                    })

                    saveLoading.value = false

                    const alerts = useAlertsStore()
                    alerts.putAlert({ 'code': 'savaBasicInfoSuccess', 'scope': 'general' })
                } catch (error: any) {
                    saveLoading.value = false

                    if (error.response?.data.code && error.response?.data.code === "Err-18") {
                        formErrors.username = constants.USERNAME_TAKEN_ERROR_MESSAGE
                        return
                    }

                    if (error.response?.status && error.response.status === constants.INVALID_PHONE_ERROR_CODE) {
                        formErrors.phone = constants.INVALID_PHONE_ERROR_MESSAGE
                        return
                    }

                    const alerts = useAlertsStore()
                    alerts.putAlert({ 'code': 'savaBasicInfoFail', 'scope': 'general' })
                }
            } catch (error: any) {
                error.inner.forEach((e: any) => {
                    // @ts-ignore
                    formErrors[e.path] = e.message;
                });
                saveLoading.value = false
            }
        }
    }

    const updateUserState = (data: IUser) => {
        if (typeof window !== 'undefined') {
            userStore.addUser(data)
            sessionStorage.setItem('user', JSON.stringify(user.value))
        }
    }

    const resetFormData = () => {
        formData.first_name = user.value.first_name
        formData.last_name = user.value.last_name
        formData.username = user.value.username
        formData.gender = user.value.gender
        formData.phone = user.value.phone ?? ''

        // rest address
        address.value = user.value.address
    }

    const saveAvatar = async () => {
        try {
            const avatarFormStore = useAvatarFormStore()
            await avatarFormStore.saveChanges()
        } catch (error) {
            // console.error(error)
            saveLoading.value = false
        }
    }

    const clearData = () => {
        resetFormData()

        hasChanges.value = false
    }

    return {
        formData,
        formErrors,
        hasChanges,
        saveLoading,
        address,

        saveChanges,
        resetFormData,
        clearData
    }
})