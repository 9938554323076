import { useUserStore } from "@/stores/user";
import { getCookie, getUserFromLocalStorage } from "@/utils/functions";
import { axios } from "../axios/config";

/**
 * API function to login in to client space
 * @param payload 
 * @returns { Promise }
 */
export const signInAsClient = async (payload: any) => {
    try {
        const response = await axios.post('auth/sign_in', payload);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to signup in to client space
 * @param payload 
 * @returns { Promise }
 */
export const signUpAsClient = async (payload: any) => {
    try {
        const response = await axios.post('auth/sign_up', payload);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to reset password
 * @param payload 
 * @returns { Promise }
 */
export const resetPassword = async (payload: any) => {
    try {
        const response = await axios.post('auth/reset_password', payload);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to change password
 * @param payload 
 * @returns { Promise }
 */
export const changePassword = async (payload: any) => {
    const user = getUserFromLocalStorage()

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user?.token.access}`
        }
    };

    try {
        const response = await axios.post('auth/change_password', payload, config);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to get client profile info
 * @returns { Promise }
 */
export const getProfileInfo = async () => {
    // const user = getUserFromLocalStorage()
    const cookieToken = getCookie("topela_token")

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${cookieToken?.access}`
        }
    };

    try {
        const response = await axios.get('auth/me/' + cookieToken?.account_id, config);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to modify client profile info
 * @param payload 
 * @returns { Promise }
 */
export const modifyProfileInfo = async (payload: any) => {
    const user = getUserFromLocalStorage()

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user?.token.access}`
        }
    }

    const userStore = useUserStore()
    const userObj = userStore.getUser

    // We need to send a full user object to the update API
    // so use the user object in store and replace the fields to be modified
    // by those from the payload

    // make an array of entries from the user object
    let userArray = Object.entries(userObj)

    // filter out the fields that are not to be modified
    let filteredArray = userArray.filter(([key, value]) => {
        return !(Object.keys(payload).includes(key))
    })

    // make an object from the filtered array and the payload
    let completePayload = {
        ...Object.fromEntries(filteredArray),
        ...payload
    }

    if (!completePayload.address || completePayload.address.is_empty) {
        delete completePayload.address
    }

    if (completePayload.token) delete completePayload.token

    // console.log("completePayload", completePayload)
    try {
        const response = await axios.put('auth/me', completePayload, config);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to update client avatar
 * @param payload 
 * @returns { Promise }
 */
export const updateAvatar = async (payload: any) => {
    const user = getUserFromLocalStorage()

    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${user?.token.access}`
        }
    }
    try {
        const response = await axios.post('auth/avatar/' + user?.account_id, payload, config);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to delete client avatar
 * @returns { Promise }
 */
export const deleteAvatarService = async () => {
    const user = getUserFromLocalStorage()

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user?.token.access}`
        }
    }

    return axios.delete('auth/avatar/' + user?.account_id, config)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to verify contact
 * @param payload 
 * @returns { Promise }
 */
export const verifyContact = async (payload: any) => {
    const user = getUserFromLocalStorage()

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user?.token.access}`
        }
    }
    return axios.post('auth/verify_contact', payload, config)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((err) => {
            return Promise.reject(err)
        })
}

/**
 * API function to verify OTP code
 * @param payload 
 * @returns { Promise }
 */
export const verifyCode = async (payload: any) => {
    const user = getUserFromLocalStorage()

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user?.token.access}`
        }
    }
    try {
        const response = await axios.put('auth/verify_contact', payload, config);
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}

/**
 * API function to get captcha
 * @returns { Promise }
 */
export const getCaptcha = async () => {
    try {
        const response = await axios.get('auth/get_captcha');
        return await Promise.resolve(response);
    } catch (err) {
        return await Promise.reject(err);
    }
}