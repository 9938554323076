// https://vike.dev/onCreateApp
export { onCreateApp }

import type { OnCreateAppSync } from 'vike-vue'
import VueGtag from "vue-gtag"
import { setAppInstance } from './appInstance'

import '@/assets/css/app-invoice.min.css'
import '@/assets/css/invoice.css'
import '@/assets/css/components.css'
import '@/assets/css/colors.css'
import '@/assets/css/style2.css'
import '@/assets/css/line-icons.css'
import '@/assets/css/magnific-popup.css'
import '@/assets/css/nivo-lightbox.css'
import '@/assets/css/main.css'
import '@/assets/css/responsive.css'
import '@/assets/css/shepherd.min.css'
import '@/assets/css/ext-component-tour.min.css'

import "bootstrap/dist/css/bootstrap.min.css"
import '@/style.css'

if (typeof window !== 'undefined') {
    import("bootstrap")
}

import '@/assets/css/style.css'
import 'vue-loaders/dist/vue-loaders.css';
import '@trevoreyre/autocomplete-vue/dist/style.css'
import 'vue-loading-overlay/dist/css/index.css'
import 'animate.css';

import i18n from "@/localization/i18n";
import VueLoaders from "vue-loaders";
import VueSmoothScroll from "vue3-smooth-scroll";
import VueEasyLightbox from "vue-easy-lightbox";
import { LoadingPlugin } from "vue-loading-overlay";
import FontAwesomeIcon from "@/utils/fontawesome.ts";
import CountryFlag from "vue-country-flag-next";
import VOtpInput from "vue3-otp-input";
import animateScroll from "@/utils/directives/animateScroll.ts";

import { defineAsyncComponent } from "vue"
// @ts-ignore
import Autocomplete from '@trevoreyre/autocomplete-vue'
// @ts-ignore
import VueProgressBar from "@aacassandra/vue3-progressbar"

// @ts-ignore
import * as Sentry from '@sentry/vue'

/**
 * Definition of the material icon components
 */
const ImageOff = defineAsyncComponent(() => import('vue-material-design-icons/ImageOff.vue'))
const Image = defineAsyncComponent(() => import('vue-material-design-icons/Image.vue'))
const FolderOffOutline = defineAsyncComponent(() => import('vue-material-design-icons/FolderOffOutline.vue'))
const EyeOutline = defineAsyncComponent(() => import('vue-material-design-icons/EyeOutline.vue'))
const Twitter = defineAsyncComponent(() => import('vue-material-design-icons/Twitter.vue'))
const Facebook = defineAsyncComponent(() => import('vue-material-design-icons/Facebook.vue'))
const Instagram = defineAsyncComponent(() => import('vue-material-design-icons/Instagram.vue'))
const Skype = defineAsyncComponent(() => import('vue-material-design-icons/Skype.vue'))
const Linkedin = defineAsyncComponent(() => import('vue-material-design-icons/Linkedin.vue'))
const GooglePlus = defineAsyncComponent(() => import('vue-material-design-icons/GooglePlus.vue'))
const CheckCircleOutline = defineAsyncComponent(() => import('vue-material-design-icons/CheckCircleOutline.vue'))
const AlertCircleOutline = defineAsyncComponent(() => import('vue-material-design-icons/AlertCircleOutline.vue'))
const Cancel = defineAsyncComponent(() => import('vue-material-design-icons/Cancel.vue'))
const ContentSaveEditOutline = defineAsyncComponent(() => import('vue-material-design-icons/ContentSaveEditOutline.vue'))
const Pen = defineAsyncComponent(() => import('vue-material-design-icons/Pen.vue'))
const EyeOffOutline = defineAsyncComponent(() => import('vue-material-design-icons/EyeOffOutline.vue'))
const AccountOutline = defineAsyncComponent(() => import('vue-material-design-icons/AccountOutline.vue'))
const CogOutline = defineAsyncComponent(() => import('vue-material-design-icons/CogOutline.vue'))
const ChatQuestionOutline = defineAsyncComponent(() => import('vue-material-design-icons/ChatQuestionOutline.vue'))
const Power = defineAsyncComponent(() => import('vue-material-design-icons/Power.vue'))
const ChatOutline = defineAsyncComponent(() => import('vue-material-design-icons/ChatOutline.vue'))
const MenuDown = defineAsyncComponent(() => import('vue-material-design-icons/MenuDown.vue'))
const Alert = defineAsyncComponent(() => import("vue-material-design-icons/Alert.vue"))
const Close = defineAsyncComponent(() => import('vue-material-design-icons/Close.vue'))
const Check = defineAsyncComponent(() => import('vue-material-design-icons/Check.vue'))
const PencilBoxOutline = defineAsyncComponent(() => import('vue-material-design-icons/PencilBoxOutline.vue'))
const Refresh = defineAsyncComponent(() => import('vue-material-design-icons/Refresh.vue'))
const FileCompare = defineAsyncComponent(() => import('vue-material-design-icons/FileCompare.vue'))
const Phone = defineAsyncComponent(() => import('vue-material-design-icons/Phone.vue'))
const Email = defineAsyncComponent(() => import('vue-material-design-icons/Email.vue'))
const MapMarkerCheckOutline = defineAsyncComponent(() => import('vue-material-design-icons/MapMarkerCheckOutline.vue'))
const Cookie = defineAsyncComponent(() => import('vue-material-design-icons/Cookie.vue'))
const OpenInNew = defineAsyncComponent(() => import('vue-material-design-icons/OpenInNew.vue'))
const CameraOffOutline = defineAsyncComponent(() => import('vue-material-design-icons/CameraOffOutline.vue'))
const MapMarkerAlertOutline = defineAsyncComponent(() => import('vue-material-design-icons/MapMarkerAlertOutline.vue'))
const Magnify = defineAsyncComponent(() => import('vue-material-design-icons/Magnify.vue'))
const FileDocumentOutline = defineAsyncComponent(() => import('vue-material-design-icons/FileDocumentOutline.vue'))
const FileDocument = defineAsyncComponent(() => import('vue-material-design-icons/FileDocument.vue'))
const Wrench = defineAsyncComponent(() => import('vue-material-design-icons/Wrench.vue'));
const FileOutline = defineAsyncComponent(() => import('vue-material-design-icons/FileOutline.vue'))
const HelpCircleOutline = defineAsyncComponent(() => import('vue-material-design-icons/HelpCircleOutline.vue'))
const ArrowLeftBold = defineAsyncComponent(() => import('vue-material-design-icons/ArrowLeftBold.vue'))
const AlertOutline = defineAsyncComponent(() => import('vue-material-design-icons/AlertOutline.vue'))
const InformationOutline = defineAsyncComponent(() => import('vue-material-design-icons/InformationOutline.vue'))
const Information = defineAsyncComponent(() => import('vue-material-design-icons/Information.vue'))
const CloseCircleOutline = defineAsyncComponent(() => import('vue-material-design-icons/CloseCircleOutline.vue'))
const DotsVertical = defineAsyncComponent(() => import('vue-material-design-icons/DotsVertical.vue'))
const Microphone = defineAsyncComponent(() => import('vue-material-design-icons/Microphone.vue'))
const Send = defineAsyncComponent(() => import('vue-material-design-icons/Send.vue'))
const Square = defineAsyncComponent(() => import('vue-material-design-icons/Square.vue'))
const PauseCircleOutline = defineAsyncComponent(() => import('vue-material-design-icons/PauseCircleOutline.vue'))
const Play = defineAsyncComponent(() => import('vue-material-design-icons/Play.vue'))
const Pause = defineAsyncComponent(() => import('vue-material-design-icons/Pause.vue'))
const Delete = defineAsyncComponent(() => import('vue-material-design-icons/Delete.vue'))
const Music = defineAsyncComponent(() => import('vue-material-design-icons/Music.vue'))
const ChevronDoubleDown = defineAsyncComponent(() => import('vue-material-design-icons/ChevronDoubleDown.vue'))
const MessageBulletedOff = defineAsyncComponent(() => import('vue-material-design-icons/MessageBulletedOff.vue'))
const TimerSandComplete = defineAsyncComponent(() => import('vue-material-design-icons/TimerSandComplete.vue'))
const TrashCanOutline = defineAsyncComponent(() => import('vue-material-design-icons/TrashCanOutline.vue'))
const HammerWrench = defineAsyncComponent(() => import('vue-material-design-icons/HammerWrench.vue'))
const FormatListBulleted = defineAsyncComponent(() => import('vue-material-design-icons/FormatListBulleted.vue'))
const Web = defineAsyncComponent(() => import('vue-material-design-icons/Web.vue'))
const CalendarMonth = defineAsyncComponent(() => import('vue-material-design-icons/CalendarMonth.vue'))
const CurrencyEur = defineAsyncComponent(() => import('vue-material-design-icons/CurrencyEur.vue'))
const TextBoxEdit = defineAsyncComponent(() => import('vue-material-design-icons/TextBoxEdit.vue'))
const WalletGiftcard = defineAsyncComponent(() => import('vue-material-design-icons/WalletGiftcard.vue'))

const onCreateApp: OnCreateAppSync = (pageContext): ReturnType<OnCreateAppSync> => {
    const { app } = pageContext

    const progressOptions = {
        color: "#FB9307",
        failedColor: "#874b4b",
        thickness: "3px",
        transition: {
            speed: "0.2s",
            opacity: "0.6s",
            termination: 300,
        },
        autoRevert: true,
        location: "top",
        inverse: false,
    };

    // @ts-ignore
    app.use(i18n)
    // @ts-ignore
    //   app.use(VueAnimateOnScroll)
    app.use(VueProgressBar, progressOptions)
    app.use(VueLoaders);
    app.use(VueSmoothScroll)

    const ga_tag = import.meta.env.VITE_GOOGLE_ANALYTICS_TAG
    app.use(VueGtag, {
        config: { id: ga_tag }
    })

    app.use(VueEasyLightbox)
    app.use(LoadingPlugin)

    const isDevMode = import.meta.env.DEV

    if (!isDevMode) {
        try {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration(),
                ],
                beforeSend(event, hint) {
                    if (hint && hint.originalException instanceof Error) {
                        return event; // Capture and send errors
                    }
                    return null; // Ignore non-error events
                },
                tracingOptions: {
                    trackComponents: true,
                },
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        } catch (e) {
            console.log('Error initializing Sentry', e)
        }
    }

    app.component('font-awesome-icon', FontAwesomeIcon)
    app.component('country-flag', CountryFlag)
    app.component('autocomplete', Autocomplete)
    app.component('image-off', ImageOff)
    app.component('folder-off-outline', FolderOffOutline)
    app.component('eye-outline', EyeOutline)
    app.component('twitter', Twitter)
    app.component('instagram', Instagram)
    app.component('facebook', Facebook)
    app.component('skype', Skype)
    app.component('linkedin', Linkedin)
    app.component('google-plus', GooglePlus)
    app.component('check-circle-outline', CheckCircleOutline)
    app.component('alert-circle-outline', AlertCircleOutline)
    app.component('cancel', Cancel)
    app.component('content-save-edition', ContentSaveEditOutline)
    app.component('pen', Pen)
    app.component('eye-off-outline', EyeOffOutline)
    app.component('account-outline', AccountOutline)
    app.component('cog-outline', CogOutline)
    app.component('chat-question-outline', ChatQuestionOutline)
    app.component('power', Power)
    app.component('chat-outline', ChatOutline)
    app.component('menu-down', MenuDown)
    app.component('alert', Alert)
    app.component('close', Close)
    app.component('check', Check)
    app.component('pencil-box-outline', PencilBoxOutline)
    app.component('refresh', Refresh)
    app.component('v-otp-input', VOtpInput)
    // app.component('MazPhoneNumberInput', MazPhoneNumberInput) not used
    app.component('file-compare', FileCompare)
    app.component('phone', Phone)
    app.component('email', Email)
    app.component('map-marker-check-outline', MapMarkerCheckOutline)
    app.component('cookie', Cookie)
    app.component('open-in-new', OpenInNew)
    app.component('camera-off-outline', CameraOffOutline)
    app.component('map-marker-alert-outline', MapMarkerAlertOutline)
    app.component('magnify', Magnify)
    app.component('file-document-outline', FileDocumentOutline)
    app.component('file-document', FileDocument)
    app.component('wrench', Wrench)
    app.component('file-outline', FileOutline)
    app.component('help-circle-outline', HelpCircleOutline)
    app.component('arrow-left-bold', ArrowLeftBold)
    app.component('alert-outline', AlertOutline)
    app.component('info-outline', InformationOutline)
    app.component('info', Information)
    app.component('close-circle-outline', CloseCircleOutline)
    app.component('dots-vertical', DotsVertical)
    app.component('microphone', Microphone)
    app.component('send', Send)
    app.component('square', Square)
    app.component('pause-circle-outline', PauseCircleOutline)
    app.component('play', Play)
    app.component('pause', Pause)
    app.component('delete', Delete)
    app.component('music', Music)
    app.component('chevron-double-down', ChevronDoubleDown)
    app.component('message-bulleted-off', MessageBulletedOff)
    app.component('timer-sand-complete', TimerSandComplete)
    app.component('trash-can-outline', TrashCanOutline)
    app.component('hammer-wrench', HammerWrench)
    app.component("format-list-bulleted", FormatListBulleted)
    app.component("web", Web)
    app.component("calendar-month", CalendarMonth)
    app.component("image-icon", Image)
    app.component("currency-eur", CurrencyEur)
    app.component("text-box-edit", TextBoxEdit)
    app.component('wallet-giftcard', WalletGiftcard)

    app.directive('animate-scroll', animateScroll)

    // @ts-ignore
    app.config.productionTip = false

    // console.log(`Vue version: ${app.version}`)

    setAppInstance(app)
}